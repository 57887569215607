<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "100px", label: "ID Jadwal", name: "-" },
      { width: "auto", label: "Jenis Assesment", name: "-" },
      { width: "auto", label: "Petugas Kelas", name: "-" },
      { width: "auto", label: "Tanggal Mulai", name: "-" },
      { width: "auto", label: "Tanggal Selesai", name: "-" },
      { width: "auto", label: "Status", name: "-" },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -ka tidak ada sorting kasih name dengan karakter -
    ];

    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          href: "/",
          active: true,
        },
      ],
      user_name: localStorage.session_name,
      user_profile: {},

      // data table
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      tableData: {
        per_page: 10,
        search: "",
        sort_field: "idjadwal",
        sort_order: "desc",
        filter_status: "1", // untuk filtering data
        nip: localStorage.session_nip,
        user_id: localStorage.session_user_id,
        start_date: "", // new property for start date
        end_date: "", // new property for end date
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    this.getProfile();
    this.getDataTable();
  },
  methods: {
    getProfile() {
      let self = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/users/" + localStorage.session_user_id + "/profile",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config).then((response) => {
        console.log(response);
        let response_data = response.data.data;
        self.user_profile = response_data.master_user;
      });
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "dashboard-admin") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          let response_data = response.data;
          let response_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_fix.list;
            self.configPagination(response_fix.pagination);
            self.currentTablePage = response_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          self.loadingTable = false;
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Digital Assessment Center Dashboard asdas</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row mt-2">
              <div class="form-group">
                <label class="col-form-label col-lg-3"> NIP </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_nip }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Nama </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">{{ user_name }}</label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Jabatan </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_namajenjangjabatan }}</span>
                  <span v-else> - </span>
                </label>
              </div>
              <div class="form-group">
                <label class="col-form-label col-lg-3"> Unit </label>
                <label class="col-form-label mx-2"> : </label>
                <label class="col-form-label">
                  <span v-if="user_profile != undefined">{{ user_profile.u_unit }}</span>
                  <span v-else> - </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Jadwal Asesmen</h4>
            <div class="row mb-2">
              <div class="col-md-3">
                <label for="startDate">Tanggal Mulai:</label>
                <input type="date" class="form-control form-control-sm" id="startDate" v-model="tableData.start_date" @input="getDataTable()" />
              </div>
              <div class="col-md-3">
                <label for="endDate">Tanggal Selesai:</label>
                <input type="date" class="form-control form-control-sm" id="endDate" v-model="tableData.end_date" @input="getDataTable()" />
              </div>
            </div>
            <div class="row mb-2 mt-4">
              <div class="col-md-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.per_page" @change="getDataTable()">
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-5">&nbsp;</div>
              <div class="col-md-3">
                <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
              </div>
            </div>
            <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="9"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td class="text-center" colspan="9">Data Tidak Tersedia</td>
                </tr>
                <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                  <td class="text-center">
                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                    <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                  </td>
                  <td>#{{ row_data.idjadwal }}</td>
                  <td>
                    {{ row_data.j_txt_dirkom }}
                  </td>
                  <td>
                    {{ row_data.j_txt_ptl }}
                  </td>
                  <td class="text-center">
                    {{ row_data.j_tanggal_mulai }}
                  </td>
                  <td class="text-center">
                    {{ row_data.j_tanggal_sampai }}
                  </td>

                  <td class="text-center">
                    <b-badge v-if="row_data.status == 'BELUM'" pill variant="primary">
                      {{ row_data.status }}
                    </b-badge>
                    <b-badge v-else-if="row_data.status == 'SUDAH'" pill variant="danger">
                      {{ row_data.status }}
                    </b-badge>
                    <b-badge v-else pill variant="success">
                      {{ row_data.status }}
                    </b-badge>
                  </td>
                  <td width="160px" class="text-center">
                    <div class="btn-group btn-group-sm">
                      <router-link
                        :to="{
                          name: 'monitoring-admin',
                          params: { id: row_data.idjadwal },
                        }"
                        class="btn btn-info btn-sm"
                        ><i class="fa fa-search"></i> Detail</router-link
                      >

                      <!-- <button v-if="row_data.peran == 'PTL'" class="btn btn-primary btn-sm">
                        <i class="bx bxs-cog"></i>
                        Setting
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </datatable>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
